

















































































































































































































import Vue from 'vue';
import { message } from 'ant-design-vue';
import * as api from '@/api/appManage';
import moment from 'moment';
const DEFAULT_LOGO =
  'https://hzjt-yunzaojia.oss-cn-beijing.aliyuncs.com/20211215/y00wottnw1e-%E5%BA%94%E7%94%A8.png';
export default Vue.extend({
  name: 'appManageList',
  data() {
    return {
      listLoading: false,
      searchForm: {
        searchValue: 1,
      },
      listData: [],
      roleCodesList: [], //应用管理员角色列表
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
        onChange: (page, pageSize) => {
          this['pagination'].current = page;
          this['pagination'].pageSize = pageSize;
          this['getApplicationList']();
        },
        onShowSizeChange: (current, size) => {
          this['pagination'].current = current;
          this['pagination'].pageSize = size;
          this['getApplicationList']();
        },
      },
      visible: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      form: {
        applicationName: '',
        applicationLogo: '',
        appAuthCallbackUrl: '', //租户回调地址
        userCallbackUrl: '', //子账号回调地址
        extend1: '', //子应用跳转链接
        roleCodes: [],
        description: '',
      },
      rules: {
        applicationName: [
          {
            required: true,
            message: '请输入产品名称',
            trigger: 'blur',
          },
          {
            min: 0,
            max: 20,
            message: '产品名称不能超过20个字符',
            trigger: 'blur',
          },
        ],
        appAuthCallbackUrl: [
          {
            required: true,
            trigger: 'blur',
            validator: this['validateCallbackUrl'],
          },
        ],
        userCallbackUrl: [
          {
            required: true,
            trigger: 'blur',
            validator: this['validateCallbackUrl'],
          },
        ],
        // extend1: [
        //   {
        //     required: true,
        //     trigger: 'blur',
        //     validator: this['validateCallbackUrl'],
        //   },
        // ],
        roleCodes: [
          {
            required: true,
            message: '请选择产品管理员角色',
          },
        ],
        description: [
          {
            min: 0,
            max: 200,
            message: '产品描述不超过200个字符',
            trigger: 'blur',
          },
        ],
      },
      previewVisible: false,
      previewImage: '',
    };
  },
  mounted() {
    this.getApplicationList();
  },
  methods: {
    moment,
    validateCallbackUrl(rule, value, callback) {
      const reg = /(http|https):\/\/([\w.]+\/?)\S*/;
      if (value === '') {
        let msg = '';
        switch (rule.field) {
          case 'appAuthCallbackUrl':
            msg = '请输入租户回调地址';
            break;
          case 'userCallbackUrl':
            msg = '请输入子账号回调地址';
            break;
          // case 'extend1':
          //   msg = '请输入子应用跳转地址';
        }
        callback(new Error(msg));
      } else if (!reg.test(value)) {
        callback(
          new Error('请输入以http://或https://开头,且符合URL规范的地址')
        );
      } else {
        //地址探活测试
        api.callbackUrlVerify(value).then((res) => {
          if (res.status === 200) {
            // console.log(res.result);
            if (res.result) {
              callback();
            } else {
              callback(new Error('当前地址不可用'));
            }
          } else {
            message.error(res.message);
          }
        });
      }
    },
    getApplicationList() {
      this.listLoading = true;
      api
        .getApplicationList(
          this.pagination.current,
          this.pagination.pageSize,
          this.searchForm.searchValue ? this.searchForm.searchValue : null
        )
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.result);
            this.pagination.total = res.result.total;
            this.listData = res.result.list;
          }
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    handleSearch() {
      this.pagination.current = 1;
      this.getApplicationList();
    },
    showModal() {
      this.visible = true;
      this.getAgenciesRoles();
    },
    getAgenciesRoles() {
      api.getAgenciesRoles('SUPER_ADMIN', 'SUPER_ADMIN', 'A').then((res) => {
        if (res.status === 200) {
          // console.log(res.result);
          this.roleCodesList = res.result;
        } else {
          message.error(res.message);
        }
      });
    },
    handleCancel() {
      this.previewVisible = false;
    },
    onCancel() {
      this.visible = false;
      this.resetForm();
    },
    updateApplicationLogoUrl(url: string) {
      this.$set(this.form, 'applicationLogo', url);
    },
    onSubmit() {
      this.submitLoading = true;
      if (!this.form.applicationLogo) {
        // 若没有上传logo，则使用默认logo
        this.form.applicationLogo = DEFAULT_LOGO;
      }
      (this.$refs.ruleForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          api
            .addApplication(this.form)
            .then((res) => {
              // console.log('addApplication', res);
              if (res.status === 200) {
                // console.log(res.result);
                if (res.result) {
                  this.$router.push({
                    path: '/platform/appManage/details',
                    query: { id: res.result.clientId },
                  });
                }
              }
            })
            .finally(() => {
              this.submitLoading = false;
            });
          // alert('submit!');
        } else {
          // console.log('error submit!!');
          this.submitLoading = false;
          return false;
        }
      });
    },
    titleClick(id: string) {
      this.$router.push({
        path: '/platform/appManage/details',
        query: { id: id },
      });
    },
    resetForm() {
      (this.$refs.ruleForm as HTMLFormElement).resetFields();
    },
    copyToClipboard(appKey: string, appSecret: string) {
      const copyContent = `AppKey：${appKey}  AppSecret：${appSecret}`;
      // navigator clipboard 需要https等安全上下文
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(copyContent).then(
          () => {
            this.$message.success('复制成功');
          },
          () => {
            this.$message.success('您的浏览器暂不支持此功能');
          }
        );
      } else {
        // 创建text area
        let textArea = document.createElement('textarea') as any;
        textArea.value = copyContent; // 使text area不在viewport，同时设置不可见
        textArea.style.position = 'absolute';
        textArea.style.opacity = 0;
        textArea.style.left = '-999999px';
        textArea.style.top = '-999999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // 执行复制命令并移除文本框
          document.execCommand('copy') ? res : rej;
          textArea.remove();
          this.$message.success('复制成功!');
        });
      }
    },
  },
});
