/*
 * @Descripttion:
 * @Author: yuyang
 * @Date: 2021-12-06 14:18:10
 * @LastEditors: yuyang
 * @LastEditTime: 2022-04-28 16:10:35
 */
import request from '@/utils/request';
import { BaseResponse } from '@/interface';

export function getApplicationList(
  page: number,
  limit: number,
  bizStatus: number | null
): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/application`,
    method: 'GET',
    params: {
      page: page,
      limit: limit,
      bizStatus: bizStatus,
    },
  });
}

export function getAppManageDetail(id: string): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/application/${id}`,
    method: 'GET',
  });
}

export function getAgenciesRoles(
  agencyCode: string,
  productCode: string,
  queryStatus: string
): Promise<BaseResponse> {
  return request({
    url: `/agency-resource/1/agenciesRoles/queryList`,
    method: 'GET',
    params: {
      agencyCode,
      productCode,
      queryStatus,
    },
  });
}

export function getAgenciesTenant(
  currentPage: number,
  pageRecorders: number,
  productCode: string,
  extend2: string
): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/applicationAgenciesR/agencies`,
    method: 'GET',
    params: {
      currentPage,
      pageRecorders,
      productCode,
      extend2,
    },
  });
}

export function addApplication(data): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/application`,
    method: 'POST',
    data,
  });
}

export function callbackUrlVerify(url: string): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/application/callbackUrl/verify`,
    method: 'GET',
    params: {
      url,
    },
  });
}

export function getAgencyStatistics(id: string): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/applicationAgenciesR/statistics/${id}`,
    method: 'GET',
  });
}

export function getAllTransferData(agencyCode: string): Promise<BaseResponse> {
  return request({
    url: `/agency-resource/1/agenciesRoleUserR/subAccount`,
    method: 'GET',
    params: {
      agencyCode,
    },
  });
}

export function getTargetTransferData(
  appCode: string,
  agencyCode: string
): Promise<BaseResponse> {
  return request({
    url: `/agency-resource/1/agenciesApplicationRoleR/admins`,
    method: 'GET',
    params: {
      appCode,
      agencyCode,
    },
  });
}

export function saveTransferData(data): Promise<BaseResponse> {
  return request({
    url: `/agency-resource/1/agenciesApplicationRoleR/appManagAuth`,
    method: 'POST',
    data,
  });
}

export function deleteApplicationRoleR(data): Promise<BaseResponse> {
  return request({
    url: `/agency-resource/1/agenciesApplicationRoleR`,
    method: 'DELETE',
    data,
  });
}

export function changeAppStatus(
  clientId: string,
  bizStatus: string
): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/application`,
    method: 'PUT',
    data: {
      clientId,
      bizStatus,
    },
  });
}

export function changeAgenciesStatus(
  productCode: string,
  agencyCode: string,
  type: number
): Promise<BaseResponse> {
  return request({
    url: `/license-resource/1/agenciesApplication/productCode/${productCode}/agencyCode/${agencyCode}/status/${type}`,
    method: 'GET',
  });
}

export function getResourceList(
  page: number,
  limit: number,
  clientId: string,
  bizStatus: string
): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/application/resourcesPage`,
    method: 'GET',
    params: {
      page,
      limit,
      clientId,
      bizStatus,
    },
  });
}

export function getModalResourceList(
  page: number,
  limit: number,
  level: number,
  resName: string,
  PRODUCT_CODE: string,
  extend1: string
): Promise<BaseResponse> {
  return request({
    url: `/sys-resource/1/sysResourcesOauthR/resourcesPage`,
    method: 'GET',
    params: {
      page,
      limit,
      level,
      resName,
      PRODUCT_CODE,
      extend1,
    },
  });
}

export function getModalResourceListSelected(
  productCode: string
): Promise<BaseResponse> {
  return request({
    url: `/sys-resource/1/sysResourcesOauthR/oauthResources/${productCode}`,
    method: 'GET',
  });
}

export function saveResourceData(data): Promise<BaseResponse> {
  return request({
    url: `/sys-resource/1/sysResourcesOauthR/saveOauthResources`,
    method: 'POST',
    data,
  });
}

export function setResourceAlias(
  id: string,
  alias: string
): Promise<BaseResponse> {
  return request({
    url: `/sys-resource/1/sysResourcesOauthR/alias?id=${id}&alias=${alias}`,
    method: 'PUT',
  });
}

export function enableResourceStatus(id: string): Promise<BaseResponse> {
  return request({
    url: `/sys-resource/1/sysResourcesOauthR/enable/${id}`,
    method: 'POST',
  });
}

export function disableResourceStatus(id: string): Promise<BaseResponse> {
  return request({
    url: `/sys-resource/1/sysResourcesOauthR/disable/${id}`,
    method: 'POST',
  });
}
